import { Control, Controller } from "react-hook-form";

import { CheckboxField, ChipField, PhoneField, RadioField, SelectField, TextField } from "./Fields";

export type FieldConfig = {
  id?: string;
  name: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  type?: "checkbox" | "date" | "dropdown" | "email" | "input" | "radio" | "phone" | "chip";
  options?: string[] | { label: string; value: string | number | boolean }[];
  [key: string]: any;
};

const FIELDS = {
  input: TextField,
  date: TextField,
  email: TextField,
  phone: PhoneField,
  radio: RadioField,
  dropdown: SelectField,
  checkbox: CheckboxField,
  chip: ChipField,
};

type FormFieldControlledProps = {
  question: FieldConfig;
  control: Control<any>;
};

export const FormFieldControlled = ({ question, control }: FormFieldControlledProps) => {
  const Field = FIELDS[(question.type as keyof typeof FIELDS) ?? "input"];
  const id = question.id ?? question.name;

  return (
    <Controller
      control={control}
      name={question.name}
      render={({ field, ...rest }) => (
        <div className="flex flex-col gap-2 z-[1500]">
          <label htmlFor={question.name}>{question.label}</label>
          <Field {...question} {...field} id={id} />
          {rest.formState.errors[question.name] && (
            <span className="text-red-500">
              {rest.formState.errors[question.name]?.message as string}
            </span>
          )}
        </div>
      )}
    />
  );
};

import { type User } from "@/types/user";
import dayjs from "dayjs";
import * as yup from "yup";

import { dateValidator, phoneNumberValidator } from "@helpers/custom.validators";

import { confidentSkills, gender, goalsSkills, race, strengthSkills } from "./profile-skills";

type FieldType = "checkbox" | "date" | "dropdown" | "email" | "input" | "radio" | "phone" | "chip";

export type ProfileFormType = {
  title: string;
  fields: {
    id: string;
    label: string;
    type: FieldType;
    options?: { value: string; label: string }[];
    validation: yup.AnySchema;
    value: unknown;
    disabled?: boolean;
  }[];
}[];

export function getProfileForm(user: User, module: string): ProfileFormType {
  const { name, profile, organization } = user;

  const modules = {
    general: [
      {
        title: "Personal Information",
        fields: [
          {
            label: "Name",
            id: "name",
            type: "input" as const,
            validation: yup.string().required("Please enter your name"),
            value: name,
          },
          {
            label: "Phone",
            id: "phone",
            type: "phone" as const,
            validation: phoneNumberValidator,
            value: profile?.phone ?? "",
          },
          {
            label: "Gender",
            id: "gender",
            type: "dropdown" as const,
            options: gender.map(type => ({ value: type, label: type })),
            validation: yup.string().required("Please select your gender").nullable(),
            value: profile?.gender,
          },
          {
            label: "Race",
            id: "race",
            type: "dropdown" as const,
            options: race.map(type => ({ value: type, label: type })),
            validation: yup.string().nullable(),
            value: profile?.race,
          },
        ],
      },
      {
        title: "Organization",
        fields: [
          {
            label: "Role",
            id: "roleId",
            type: "dropdown" as const,
            options: organization?.roles?.map(({ id, role }) => ({
              value: String(id),
              label: role,
            })),
            validation: yup.string().required("Please select your role"),
            value: String(profile?.roleId),
          },
          {
            label: "Years of Experience",
            id: "yearsExperience",
            type: "input" as const,
            validation: yup.string(),
            value: profile?.yearsExperience ?? "",
          },
          {
            label: "Clinical Care Area/Clinical Support",
            id: "unitId",
            type: "dropdown" as const,
            options: organization?.units.map(({ id, title }) => ({
              value: String(id),
              label: title,
            })),
            validation: yup
              .string()
              .required('Please select your "Clinical Care Area/Clinical Support"'),
            value: String(profile?.unitId),
          },
        ],
      },
    ],
    goals: [
      {
        title: "Professional Goals",
        fields: [
          {
            label: "What are your professional aspirations for the next 5 years",
            id: "professionalGoals",
            type: "chip" as const,
            options: goalsSkills.map(type => ({ value: type, label: type })),
            validation: yup
              .array()
              .min(2, "Please select at least 2 options")
              .max(3, "Please select at most 3 options")
              .nullable(),
            value: profile?.professionalGoals ?? [],
          },
        ],
      },
      {
        title: "Skills",
        fields: [
          {
            id: "leastConfident",
            type: "chip" as const,
            label: "I am least confident in my abilities as it relates to:",
            options: confidentSkills.map(type => ({ value: type, label: type })),
            validation: yup.array().max(3, "Please select at most 3 options").nullable(),
            value: profile?.leastConfident ?? [],
          },
          {
            id: "strengthsToImprove",
            type: "chip" as const,
            label: "My goals for development are focused on:",
            options: strengthSkills.map(type => ({ value: type, label: type })),
            validation: yup.array().max(3, "Please select at most 3 options").nullable(),
            value: profile?.strengthsToImprove ?? [],
          },
        ],
      },
    ],
    education: [
      {
        title: "More",
        fields: [
          {
            label: "Committees",
            id: "committees",
            type: "chip" as const,
            validation: yup.array(),
            options:
              organization?.committees?.map(({ id, title }) => ({
                value: String(id),
                label: title,
              })) ?? [],
            value: profile?.committees?.map(({ id }) => id) ?? [],
            multiple: true,
          },
          {
            label: "Shared Governance",
            id: "sharedGovernance",
            type: "radio" as const,
            validation: yup.boolean().nullable(),
            value: profile?.sharedGovernance,
            options: [
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ],
          },
          {
            label: "Charge Nurse",
            id: "chargeNurse",
            type: "date" as const,
            validation: dateValidator,
            value: profile?.chargeNurse
              ? dayjs(profile?.chargeNurse).toISOString()?.substr(0, 10)
              : "",
          },
          {
            label: "Preceptor",
            id: "preceptor",
            type: "date" as const,
            validation: dateValidator,
            value: profile?.preceptor ? dayjs(profile?.preceptor).toISOString()?.substr(0, 10) : "",
          },
        ],
      },
    ],
  };

  return modules[module as keyof typeof modules];
}

export const CUSTOM_FIELDS = ["role", "interests", "hospital"];

export function getInitialsFromName(name?: string): string {
  return (
    name
      ?.match(/(\b\S)?/g)
      ?.join("")
      .match(/(^\S|\S$)?/g)
      ?.join("")
      .toUpperCase() ?? ""
  );
}

export function getSplitFullName(fullname?: string): string[] {
  if (!fullname) {
    return [];
  }

  const [firstName, ...lastName] = fullname.trim().split(/[\s,\t,\n]+/);

  return [firstName, lastName.join(" ")];
}

export const race = [
  "American Indian or Alaska Native",
  "Asian or Asian American",
  "Black or African American",
  "Hispanic or Latino/a",
  "Middle Eastern or North African",
  "Native Hawaiian or Pacific Islander",
  "White or European",
];

export const gender = ["Female", "Male", "Other"];

export const confidentSkills = [
  "My leadership ethos",
  "Administrative skills",
  "Onboarding",
  "Retention & engagement",
  "Succession planning",
  "Coaching teams",
  "Quality improvement",
  "Project and change management",
  "Financial acumen",
  "Compliance and accreditation",
  "Transforming culture",
];

export const strengthSkills = [
  "Leadership ethos",
  "Self coaching",
  "Leadership presence",
  "Leading meetings",
  "Email management",
  "Time management",
  "Onboarding staff",
  "Employee engagement",
  "Succession planning",
  "Performance management",
  "Coaching others",
  "Service recovery",
  "Evaluating KPIs",
  "Leading an RCA",
  "Leading QI",
  "Execution",
  "Accountability",
  "Leading change",
  "Financial acumen",
  "Financial decisions",
  "Financial planning",
  "Crisis management",
  "Risk management",
  "Driving compliance",
  "Creating inclusivity",
  "Combating incivility",
  "Driving Culture",
];

export const goalsSkills = [
  "Thrive in my current roles",
  "Seek a promotion",
  "Pursue a graduate degree",
  "Pursue a certification",
  "Pursue academic publications",
  "Lead a QI project",
  "Lead a nursing professional development initiatives",
  "Mentor or develop successors to my roles",
  "Deliver professional presentations",
  "Target executive-level positions such as Senior Director or Chief Nursing Officer (CNO) or Vice President of Nursings",
];

export const specialties = [
  "Emergency Department",
  "Medical Intensive Care Unit",
  "Surgical Intensive Care Unit",
  "Pediatrics Acute Care",
  "Pediatric Intermediate Care",
  "Pediatric Intensive Care Unit",
  "Neonatal Intensive Care Unit",
  "Labor and Deliver",
  "Oncology",
  "Cardiac Care/Telemetry",
  "Orthopedic/Rehab",
  "Operating Room",
  "Cardiac Stepdown",
  "Cardiac Intensive Care Unit",
  "Medical Acute Care",
  "Surgical Acute Care",
  "Trauma Intensive Care Unit",
  "Burn Unit",
  "Outpatient",
];

import { User } from "@/types/user";

export function getProfilePercentage(user: User) {
  const { name, profile } = user || {};

  const profileFields = [
    name,
    profile?.gender,
    profile?.roleId,
    profile?.yearsExperience,
    profile?.unitId,
    profile?.education.length,
    profile?.professionalGoals.length,
  ];

  const totalFields = profileFields.length;
  const filledFields = profileFields.filter(Boolean).length;

  return (filledFields / totalFields) * 100;
}

import { Skeleton } from "@components/ui/skeleton";

export const HeaderSkeleton = () => {
  return (
    <>
      <Skeleton className="hidden md:block w-[500px] h-[60px] rounded-full" />
      <Skeleton className="w-[60px] h-[60px] rounded-full" />
    </>
  );
};
